$primary: #0d51b6;
$primary_dark: #0f1c99;
$black: #000;
$purpleColor: #3b4596;
$accent: #ff6f00;
$danger: #e1464b;
$light:#f7f7f7;
$lightGrey: lightgrey;
$lightGrey--1: #a8acb4;
$lightGrey--2: #c1c1c1;
$mediumGrey: rgba(112, 112, 111, 1);
$shadow: #a7a7a7;
$violet: #949cbe;
$fontColor: #424242;
$white: #fff;
